import React, { useState, useEffect } from "react";
import axios from "axios";
import { Button, message, Alert, Skeleton } from "antd";
import { API_URI } from "../../constant/Config";

const UpdateMemberAdhesion = ({ value }) => {
    const [memberData, setMemberData] = useState(null);
    const [loading, setLoading] = useState(false);
    const [showRenewalPrompt, setShowRenewalPrompt] = useState(false);
    const [responseMessage, setResponseMessage] = useState(null);
    const token = localStorage.getItem("membre_token");

    useEffect(() => {
        const updateMember = async () => {
            setLoading(true);
            try {
                const response = await axios.post(`${API_URI}/api_member/data_member`, value, {
                    headers: { Authorization: token },
                });
                setMemberData(response.data.data_membre);
                checkRenewal(response.data.data_membre);
            } catch (error) {
                message.error("Erreur lors de la mise à jour du membre");
            }
            setLoading(false);
        };

        updateMember();
    }, [value, token]);

    const checkRenewal = (data) => {
        if (data.getGpMembrecolMembreHonoraire === 1 && !data.isValidRenewAdhesion) {
            setShowRenewalPrompt(true);
        }
    };

    const renewAdhesion = async () => {
        try {
            const response = await axios.post(`${API_URI}/api_member/renew-adhesion-member`, { data_membre: memberData }, {
                headers: { Authorization: token },
            });
            if (response.status === 200) {
                setResponseMessage(response.data.message);
                message.success(response.data.message);
                setShowRenewalPrompt(false);
            } else {
                message.error(response.data.message || "Erreur lors du renouvellement de l'adhésion");
            }
        } catch (error) {
            message.error("Erreur réseau lors du renouvellement de l'adhésion");
        }
    };

    return (
        <div style={{ maxWidth: "500px", marginLeft: "0" }}>
            {loading ? <Skeleton active /> : null}
            {showRenewalPrompt && (
                <Alert
                    message="Renouvellement d'adhésion nécessaire"
                    description={
                        <>
                            <p>Votre adhésion doit être renouvelée.</p>
                            <Button type="primary" onClick={renewAdhesion} style={{ marginTop: "10px" }}>
                                Renouveler l'adhésion
                            </Button>
                        </>
                    }
                    type="info"
                    showIcon
                    style={{ maxWidth: "500px", margin: "20px 0" }}
                />
            )}
            {responseMessage && (
                <Alert
                    message={responseMessage}
                    type="success"
                    showIcon
                    style={{ maxWidth: "500px", marginTop: "10px" }}
                />
            )}
        </div>
    );
};

export default UpdateMemberAdhesion;
