import React, { Component } from "react";
import {Form, Input, Button, Checkbox, message, Alert, Select, Typography} from "antd";
import axios from "axios";
import {FileProtectOutlined} from "@ant-design/icons";
import { API_URI } from "../../constant/Config";

const { Option } = Select;
const { Text, Link } = Typography;

class CommandesPassportForm extends Component {
    state = {
        isFormVisible: false,
        dataCmd: this.props.dataCmd,
        isSubmitted: false,
    };

    toggleFormVisibility = () => {
        this.setState({ isFormVisible: !this.state.isFormVisible });
    };

    handleFinish = async (values) => {
        const response = await axios.post(
            `${API_URI}/api_member/passport-benevolat-add`,
            values,
            {
                headers: {
                    "Content-Type": "application/json",
                },
            }
        );
        if (response.status === 200) {
            this.setState({ isSubmitted: true });
            message.success("Formulaire envoyé avec succès !");
        } else {
            message.error("Erreur lors de l'envoi du formulaire");
        }
    };

    handleFinishFailed = () => {
        message.error("Veuillez remplir tous les champs correctement.");
    };

    render() {
        const { dataCmd, isFormVisible, isSubmitted } = this.state;
        const passportData = dataCmd?.passport_benevolat?.data;

        // Commande de passport non authorisé car il faut que le membre est déjà versé le premier paiement
        if (passportData === false) {
            return null;
        }

        if (passportData !== null) {
            return (
                <Alert
                    message="Commande déjà effectuée"
                    description="Votre commande de passeport bénévolat a déjà été soumise."
                    type="info"
                    showIcon
                />
            );
        }

        if (isSubmitted) {
            return (
                <Alert
                    message="Commande réussie"
                    description="Votre commande de passeport bénévolat a été envoyée avec succès. Merci de votre confiance !"
                    type="success"
                    showIcon
                />
            );
        }

        return (
            <div>
                {!this.state.isFormVisible && (
                    <Alert
                        message="Obtenez votre passeport bénévole gratuitement !"
                        description={
                            <div>
                                Ce document officiel vous permet de valoriser vos expériences bénévoles et de faire reconnaître les compétences que vous avez acquises. Recommandé par les employeurs et les associations, il est un atout pour votre parcours personnel et professionnel. Globalong vous l’offre, ainsi que les frais d’envoi.
                                <br />
                                Cliquez ci-dessous pour le recevoir dès maintenant !
                                <br /><br />
                                En savoir plus: <Link href="https://www.globalong.com/blog/passeport-benevole/" target="_blank">Le Passeport Bénévole</Link>
                                <br /><br />

                                <Button type="primary" onClick={this.toggleFormVisibility}>
                                    <FileProtectOutlined /> Commander mon passeport bénévolat
                                </Button>
                            </div>
                        }
                        type="warning"
                        showIcon
                    />
                )}
                {this.state.isFormVisible && (
                    <Alert
                        message="Commandez votre passeport bénévolat"
                        description={
                            <div style={{ marginTop: 20 }}>
                                <Form
                                    ref={(form) => (this.formRef = form)}
                                    name="commandes_passport"
                                    layout="vertical"
                                    onFinish={this.handleFinish}
                                    onFinishFailed={this.handleFinishFailed}
                                    initialValues={{
                                        id_commande: this.state.dataCmd.data.id, // Initialisation avec la valeur de this.state.dataCmd.data.id
                                        pays: "France",
                                    }}
                                >
                                    <Form.Item
                                        label="Civilité"
                                        name="civ"
                                        rules={[{ required: true, message: "Veuillez sélectionner la civilité." }]}
                                    >
                                        <Select placeholder="Sélectionnez votre civilité">
                                            <Option value="Mme.">Mme.</Option>
                                            <Option value="M.">M.</Option>
                                        </Select>
                                    </Form.Item>

                                    <Form.Item
                                        label="Nom"
                                        name="nom"
                                        rules={[{ required: true, message: "Veuillez saisir le nom." }]}
                                    >
                                        <Input placeholder="Nom" />
                                    </Form.Item>

                                    <Form.Item
                                        label="Prénom"
                                        name="prenom"
                                        rules={[{ required: true, message: "Veuillez saisir le prénom." }]}
                                    >
                                        <Input placeholder="Prénom" />
                                    </Form.Item>

                                    <Form.Item
                                        label="Téléphone"
                                        name="numero"
                                        rules={[{ required: true, message: "Veuillez saisir le numéro." }]}
                                    >
                                        <Input placeholder="Numéro" />
                                    </Form.Item>

                                    <Form.Item
                                        label="Numéro et nom de rue"
                                        name="rue"
                                        rules={[{ required: true, message: "Veuillez saisir la rue." }]}
                                    >
                                        <Input placeholder="Rue" />
                                    </Form.Item>

                                    <Form.Item
                                        label="Code postal"
                                        name="cp"
                                        rules={[{ required: true, message: "Veuillez saisir le code postal." }]}
                                    >
                                        <Input placeholder="Code postal" />
                                    </Form.Item>

                                    <Form.Item
                                        label="Ville"
                                        name="ville"
                                        rules={[{ required: true, message: "Veuillez saisir la ville." }]}
                                    >
                                        <Input placeholder="Ville" />
                                    </Form.Item>

                                    <Form.Item
                                        label="Pays (Uniquement possible en France)"
                                        name="pays"
                                        rules={[{ required: true, message: "Veuillez sélectionner le pays." }]}
                                    >
                                        <Select placeholder="Sélectionnez votre pays">
                                            <option value="France">France</option>
                                        </Select>
                                    </Form.Item>

                                    <Form.Item
                                        name="id_commande"
                                    >
                                        <Input type="hidden" />
                                    </Form.Item>

                                    <Form.Item>
                                        <p>
                                            Les informations recueillies sur ce formulaire sont enregistrées dans un fichier
                                            informatisé par l’association Globalong pour l'envoi du document par courrier.
                                        </p>
                                    </Form.Item>

                                    <Form.Item
                                        name="terms"
                                        valuePropName="checked"
                                        rules={[
                                            {
                                                required: true,
                                                message: "Vous devez accepter les termes pour continuer.",
                                            },
                                        ]}
                                    >
                                        <Checkbox>
                                            J'accepte que mes données soient utilisées pour l'envoi du document par courrier.
                                        </Checkbox>
                                    </Form.Item>

                                    <Form.Item>
                                        <Button type="primary" htmlType="submit">
                                            Valider la commande de passeport bénévolat
                                        </Button>
                                    </Form.Item>
                                </Form>
                            </div>
                        }
                        type="warning"
                        showIcon
                    />

                )}
            </div>
        );
    }
}

export default CommandesPassportForm;
